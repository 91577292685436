<template>
  <div>
    <el-breadcrumb style="margin:0 0 10px 10px;" separator="/">
        <el-breadcrumb-item>常规配置</el-breadcrumb-item>
        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
      </el-breadcrumb>
    <div class="content">
      <div class="tt">联系我们</div>
      <div class="xian"></div>
      <div style="max-height: 620px;overflow-y: auto;">
      <el-form :model="form" :rules="rules" ref="enterForm" label-width="140px" style="width: 100%">
       
            <el-form-item label="电话：">
              <el-input class="int" v-model="form.tel" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系人：">
              <el-input class="int" v-model="form.contacts" clearable></el-input>
            </el-form-item>
            <el-form-item label="地址：">
              <el-input class="int" v-model="form.addr" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item label="内容：" prop="content">
              <template>
              <div id="lpc-quill" class="quill">
                <el-upload
                  multiple
                  :with-credentials="true"
                  :action="URL + 'api/upload'"
                  :headers="headers"
                  v-show="false"
                  ref="upload"
                  class="upload-demo"
                  :before-upload="beforeAvatarUpload"
                  :on-success="upScuccess"
                  :on-error="uploadError"
                />
                <quill-editor
                  ref="myQuillEditor"
                  v-model="form.content"
                  :options="editorOption"
                  @change="onEditorChange($event)"
                />
              </div>
            </template>
            </el-form-item> -->
            
      </el-form>
      </div>
      <div class="btn_box">
        <el-button type="cha" size="small" class="add_btn" @click="baocun">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import * as Quill from 'quill' // 引入编辑器
// import ImageResize from 'quill-image-resize-module'
// Quill.register('modules/imageResize', ImageResize)
export default {
  data() {
    return {
      editorOption: {
        // 富文本编辑器配置
        placeholder: '请在这里输入',
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
              // ['blockquote', 'code-block'], // 引用，代码块
              [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
              [{ list: 'ordered' }, { list: 'bullet' }], // 列表
              [{ script: 'sub' }, { script: 'super' }], // 上下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              [{ direction: 'rtl' }], // 文本方向
              // [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
              // [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // 几级标题
              [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
              // [{ 'font': [] }], // 字体
              [{ align: [] }], // 对齐方式
              ['clean'], // 清除字体样式
              ['image'], // 上传图片、上传视频
            ],
            handlers: {
              image: function (value) {
                if (value) {
                  document.querySelector('.upload-demo input').click()
                } else {
                  this.quill.format('image', false)
                }
              },
            },
          },
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
              width: '927px',
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
          },
        },
        theme: 'snow',
      },
      form: {
        contacts: '',
        tel: '',
        addr: '',
        id: ''
      },
      rules: {
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入简介', trigger: 'blur' },
        ]
      },
      isflow: false,
      URL: '',
      URL_img: '',
      fileList: [],
      headers: {
        token: ''
      }
    }
  },
  created() {
    this.headers.token = window.localStorage.getItem('jd_token')
    this.URL = this.$URL
    this.URL_img = this.$URL_img
    this.info()
  },
  methods: {
    upScuccess(res, file) {
      console.log(res)
      const quill = this.$refs.myQuillEditor.quill
      if (res.code === 200 && res.data !== null) {
        const length = quill.getSelection().index // 获取光标所在位置
        quill.insertEmbed(length, 'image', this.$URL_img + res.data) // 插入图片
        quill.setSelection(length + 1) // 调整光标位置到最后
      } else {
        this.$message.error('图片上传失败')
      }
      console.log(1, quill)
    },
    uploadError() {
      this.$message.error('图片上传失败')
    },
    onEditorChange() {
      this.$emit('editorContent', this.editContent)
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt1M = file.size / 1024 / 1024 < 5

      // if (!isJPG) {
      //   this.$message.error('上传图片只能是 JPG 格式!')
      // }
      if (!isLt1M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      return isLt1M
    },
    //  获取联系我们
    async info() {
      const { data: res } = await this.$http.get('api/qrcode/contact')
      console.log(res)
      this.form = {
        contacts: res.data.contacts,
        tel: res.data.tel,
        addr: res.data.addr,
        id: res.data.id
      }
    },

    // 保存
    baocun() {
      this.$refs.enterForm.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据')
        const { data: res } = await this.$http.post(
          'api/qrcode/editsystem',
          this.form
        )
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
      })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 13px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 20px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 13px auto 20px;
  }
  
  .int {
    width: 400px;
  }
  
  .add_btn {
    // width: 140px;
    margin: 60px 0 0 140px;
  }
}
</style>

